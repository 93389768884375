import React from 'react';
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ component: Component, restricted = false, ...rest }) => {
  const isLogin = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY) ? true : false
  return (
    <Route {...rest} render={props => (
      isLogin && restricted ? 
        <Redirect to="/"/>
      :<Component {...props} />
    )}/>
  )
}

export default PublicRoute;